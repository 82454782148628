<template>
  <nav class="navbar">
    <div class="container-fluid">
      <a class="navbar-brand" @click="this.$router.push('/')  ">
        <img class="img-fluid" src="../assets/logo.svg" alt="">
      </a>
      <div class="d-flex">
        <a class="a mt-2 me-1" role="button" @click="doAccount">
          <svg class="account-svg bi bi-person-fill" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            viewBox="0 0 16 16">
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg>
          My Account
        </a>
      </div>
    </div>
  </nav>

            
  <!-- DESKTOP VIEW Payment History -->
  <div class="desktop-payment-page">
    <div class="main-height">
      <section class="payment-history">
        <div class="container">
          <div class="billing-detail">
            <h1>Billing Details</h1>
            <h4>YOUR MEMBERSHIP</h4>
            <div class="card w-100">
              <h2>Plan</h2>
              <p v-if="!this.userPlan.trial">Paid - {{this.userPlan.maxDevices}} {{ this.userPlan.maxDevices == 1 ? 'Screen' : 'Screens' }}</p>
              <p v-else>Trial - {{this.userPlan.maxDevices}} {{ this.userPlan.maxDevices == 1 ? 'Screen' : 'Screens' }}</p>
              <p v-if="!this.userPlan.trial">${{this.userPlan.price}}{{this.userPlan.duration}}</p>
              <p v-else>Free</p>
            </div>
          </div>
          <div v-if="this.paymentList.length > 0" class="billing-dates" >
            <table class="w-100">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Plan</th>
                  <th>End date</th>
                  <th>Payment method</th>
                  <th>Amount</th>
                </tr>
              </thead>

              <tbody>
                <tr v-bind:key="payment.paymentId" v-for="payment in this.paymentList">
                  <td>{{payment.paymentDate}}</td>
                  <td>{{payment.plan}}</td>
                  <td>{{new Date(payment.planEndDate).toLocaleDateString()}}</td>
                  <td>{{payment.paymentSystem}}</td>
                  <td>$ {{payment.amount}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else >
            <br>
            <p>No payment information to display.</p>
            <hr>
          </div>
        </div>
      </section>
    </div>
  </div>
  
  <!-- MOBILE VIEW Payment History -->
  <div class="mobile-payment-page">
    <section class="payment-history">
      <div class="payment-inner-container">
        <div class="billing">
          <h2>Billing Details</h2>
          <h5>YOUR MEMBERSHIP</h5>
          <div class="card w-100">
            <h4>Plan</h4>
            <p v-if="!this.userPlan.trial">Paid - {{this.userPlan.maxDevices}} {{ this.userPlan.maxDevices == 1 ? 'Screen' : 'Screens' }}</p>
            <p v-else>Trial - {{this.userPlan.maxDevices}} {{ this.userPlan.maxDevices == 1 ? 'Screen' : 'Screens' }}</p>
            <p v-if="!this.userPlan.trial">${{this.userPlan.price}}{{this.userPlan.duration}}</p>
            <p v-else>Free</p>
          </div>
        </div>
        <!-- detail 1  -->
        <div v-if="this.paymentList.length > 0">
          <div v-bind:key="payment.paymentId" v-for="payment in this.paymentList">
            <PaymentItem v-bind:payment_item="payment"></PaymentItem>
          </div>
        </div>
        <div v-else>
          <br>
          <p>No payment information to display.</p>
        </div>
        <hr>
      </div>
    </section>
  </div>
  
  <Footer></Footer>

</template>

<script>
import {defineComponent} from 'vue'
import Footer from "@/components/Footer.vue";
import PaymentItem from '@/components/PaymentItem.vue'
import AuthService from "@/services/auth-service";

export default defineComponent({
  name: "Payment History",
  components: {
    PaymentItem,
    Footer
  },
  data() {
    return {
      paymentList: [],
      userPlan: null
    }
  },
  methods: {
    doAccount() {
      this.$router.push('/account')
    }
  },
  beforeMount() {
    // prevent direct navigation bugs
    if (localStorage.getItem('userPlan') == null) {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    }
    if (localStorage.getItem('paymentList')) this.paymentList = JSON.parse(localStorage.getItem('paymentList'))
    if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
  }
})
</script>

<style>
@import '../assets/style.css';
</style>
