<template>
  <div class="bill-detail">
    <hr>
    <div class="d-flex justify-content-between">
      <h4>{{payment_item.paymentDate}}</h4>
      <h3>${{payment_item.amount}}</h3>
    </div>
    <div class="bill-dates">
      <p>{{payment_item.plan}}</p>
      <p>{{payment_item.paymentDate}} - {{new Date(payment_item.planEndDate).toLocaleDateString()}}</p>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "PaymentItem",
  props : ["payment_item"],
  data() {
    return {
    }
  },
  methods: {
  }
})
</script>

<style>
@import '../assets/style.css';
</style>